module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/impressum/"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ZI Brunner","short_name":"ZIB","start_url":"/","background_color":"#fff","theme_color":"#000","display":"standalone","icon":"src/images/gatsby-icon.png","crossOrigin":"use-credentials"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
